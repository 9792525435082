import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PopupBox from "../popup/PopupBox";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { getNoOfFloors } from "../../globalAPI/Api";

const NewRoomForm = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { roomType: [] },
  });

  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectedHostel, setSelectedHostel] = useState("JSK WOMENS PG 1");

  const [popupState, setPopupState] = useState({
    isOpen: false,
    successPopup: false,
    errorPopup: false,
    roomData: null,
    roomNo: {},
  });
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames && storedBranchNames);
  let hostelName = localStorage.getItem("hostelName");

  const { data, isLoading, isError, refetch } = useQuery(
    "getNoOfFloors",
    getNoOfFloors
  );

  let nooffloor = data && data?.data;
  // console.log("data----", nooffloor);
  const numberofrooms = watch("selectFloors");
  // console.log("flooe", numberofrooms);
  const getNoOfRooms = async () => {
    let reponse = await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/lastroomnobyfloor/${hostelName}/${numberofrooms}`
    );
    return reponse.data;
  };

  const { data: fetchnoofrooms } = useQuery(
    ["getNoOfRooms", hostelName, numberofrooms],
    getNoOfRooms,
    {
      enabled: !!numberofrooms, // Only fetch when selectFloors is available
    }
  );

  const branchName = watch("branchName");
  const noOfFloor = watch("noOfFloors");
  const selectFloors = watch("selectFloors");
  const noOfRooms = watch("noOfRooms");

  const handleOpenPopup = (roomNumber) => {
    setPopupState({
      isOpen: true,
      roomData: {
        branchName,
        noOfFloor,
        selectFloors,
        noOfRooms,
      },
      roomNo: { roomNumber },
    });
  };

  const handleClosePopup = () => {
    setPopupState({
      isOpen: false,
      roomData: null,
      roomNo: {},
    });
  };
  useEffect(() => {
    setValue("noOfFloors", nooffloor);
    setNoOfFloors(nooffloor);
    setValue("noOfRooms", fetchnoofrooms);
  }, [nooffloor, setValue, fetchnoofrooms]);
  console.log("selected hostel : ", selectedHostel);
  const [noOfFloors, setNoOfFloors] = useState(0);
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedPortion, setSelectedPortion] = useState("");
  const [floorPortionCounts, setFloorPortionCounts] = useState({});
  const [roomCounts, setRoomCounts] = useState({});

  // Generate floor options based on the number of floors
  const generateFloorOptions = () => {
    return Array.from(
      { length: noOfFloors },
      (_, index) => `Floor ${index + 1}`
    );
  };

  const numberToRoman = (num) => {
    const romanNumerals = {
      1: "I",
      2: "II",
      3: "III",
      4: "IV",
      5: "V",
      6: "VI",
      7: "VII",
      8: "VIII",
      9: "IX",
      10: "X",
    };

    return romanNumerals[num] || "";
  };

  // Generate portion options based on the selected floor
  const generatePortionOptions = () => {
    const count = Math.min(floorPortionCounts[selectedFloor] || 0, 10); // Limit to 10
    return Array.from({ length: count }, (_, index) =>
      numberToRoman(index + 1)
    );
  };

  return (
    <>
      <div className="row pt-2 pb-2 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-sm-6 col-md-6">
          <div className="new-register-title-block">
            <img
              src="/assets/images/arrow-blue.png"
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">NEW ROOM</h5>
          </div>
        </div>
        <div className="col-12 col-sm-6 d-flex justify-content-between justify-content-md-end align-items-center gap-2">
          <Controller
            name="selectHostel"
            control={control}
            defaultValue="JSK WOMENS PG 1"
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <select
                {...field}
                value={selectedHostel}
                onChange={(e) => {
                  field.onChange(e); // Update the react-hook-form field
                  setSelectedHostel(e.target.value); // Update the state variable
                }}
                className={`selectDropDown ${errors.role ? "error" : ""}`} // Conditional class for error
              >
                <option value="">Branch Name</option>
                {branchNameArray?.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
          <button
            className="view-register-btn"
            onClick={() => {
              navigate("/newRoom");
            }}
          >
            VIEW ROOM
          </button>
        </div>
      </div>

      <div className="new-room-form-main-block">
        <div className="new-room-form-block my-3">
          <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                BRANCH NAME{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="branchName"
                control={control}
                defaultValue="JSK WOMENS PG"
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <select
                    {...field}
                    className={`newRoomInputField ${
                      errors.branchName ? "error" : ""
                    }`}
                  >
                    <option value="">Branch Name</option>
                    <option value="JSK WOMENS PG">JSK WOMENS PG</option>
                  </select>
                )}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <label className="newRoomInputLabel">
                NO OF FLOORS{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="noOfFloors"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    className="newRoomInputField"
                    placeholder="No Of Floors"
                    type="number"
                    min="1"
                    onChange={(e) => {
                      const count = parseInt(e.target.value, 10) || 0;
                      setNoOfFloors(count);
                      field.onChange(count);
                      // Reset portion and room counts
                      setFloorPortionCounts({});
                      setRoomCounts({});
                      setSelectedFloor(""); // Reset selected floor
                      setSelectedPortion(""); // Reset selected portion
                    }}
                    value={field.value}
                  />
                )}
                rules={{ required: "This field is required" }}
              />
            </div>
          </div>

          <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                SELECT FLOOR{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="selectFloors"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select
                    className="newRoomInputField"
                    onChange={(e) => {
                      const floor = e.target.value;
                      setSelectedFloor(floor);
                      setSelectedPortion(""); // Reset selected portion when changing floor
                      field.onChange(floor);
                    }}
                    value={field.value}
                  >
                    <option value="">Select Floor</option>
                    {generateFloorOptions().map((floor) => (
                      <option key={floor} value={floor}>
                        {floor}
                      </option>
                    ))}
                  </select>
                )}
                rules={{ required: "This field is required" }}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <label className="newRoomInputLabel">NO OF PORTION</label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="noOfPortion"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <input
                    placeholder="No of Portions"
                    className="newRoomInputField"
                    type="number"
                    min="0"
                    onChange={(e) => {
                      const count = parseInt(e.target.value, 10) || 0;
                      setFloorPortionCounts((prev) => ({
                        ...prev,
                        [selectedFloor]: count,
                      }));
                      field.onChange(count);
                    }}
                    value={floorPortionCounts[selectedFloor] || 0} // Default to 0 if no portions defined
                  />
                )}
              />
            </div>
          </div>
          <div className="row mx-4 p-0 mt-4 mb-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-3 my-1">
              <label className="newRoomInputLabel">
                SELECT PORTION{" "}
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
              </label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="selectPortion"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select
                    className="newRoomInputField"
                    onChange={(e) => {
                      const portion = e.target.value;
                      setSelectedPortion(portion);
                      field.onChange(portion);
                    }}
                    value={field.value}
                  >
                    <option value="">Select Portion</option>
                    {selectedFloor &&
                      generatePortionOptions().map((portion) => (
                        <option key={portion} value={portion}>
                          {portion}
                        </option>
                      ))}
                  </select>
                )}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <label className="newRoomInputLabel">NO OF ROOMS</label>
            </div>
            <div className="col-12 col-sm-6 col-md-3 my-2">
              <Controller
                name="noOfRooms"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <input
                    placeholder="No of Rooms"
                    className="newRoomInputField"
                    type="number"
                    min="0"
                    onChange={(e) => {
                      const count = parseInt(e.target.value, 10) || 0;
                      setRoomCounts((prev) => ({
                        ...prev,
                        [`${selectedFloor}-${selectedPortion}`]: count,
                      }));
                      field.onChange(count);
                    }}
                    value={field.value || 0}
                  />
                )}
              />
            </div>
          </div>

          <div className="row mx-5 p-0 mt-4 mb-2">
            <div className="col-12 newRoomInputBlockContainer">
              <p className="new-room-para pt-2">
                <span> SELECTED FLOOR: {selectedFloor || "None"}</span>
                <span>SELECTED PORTION: {selectedPortion || "None"}</span>
              </p>

              <div className="row">
                {/* Display Rooms based on the number of rooms entered */}
                {roomCounts[`${selectedFloor}-${selectedPortion}`] > 0 &&
                  [
                    ...Array(roomCounts[`${selectedFloor}-${selectedPortion}`]),
                  ].map((_, index) => (
                    <div
                      key={`${selectedFloor}-${selectedPortion}-room-${index}`}
                      className="col-12 col-sm-6 col-md-3 my-1 d-flex justify-content-center"
                    >
                      <div
                        className="room-container"
                        onClick={() => {
                          handleOpenPopup(
                            `F${selectedFloor.replace(
                              "Floor ",
                              ""
                            )} - ${selectedPortion} - ${index + 1}`
                          );
                        }}
                      >
                        {`F${selectedFloor.replace(
                          "Floor ",
                          ""
                        )} - ${selectedPortion} - ${index + 1}`}
                      </div>
                    </div>
                  ))}
                {roomCounts[`${selectedFloor}-${selectedPortion}`] === 0 &&
                  selectedPortion && <p>No rooms assigned for this portion.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PopupBox
        isOpen={popupState.isOpen}
        onClose={handleClosePopup}
        roomData={popupState.roomData}
        roomNo={popupState.roomNo}
        state={state}
        selectedHostel={selectedHostel}
      />
    </>
  );
};

export default NewRoomForm;
