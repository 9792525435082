import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import BillingAddInputField from "../components/BillingAddInputField";
import PopUpModal from "../components/PopUpModal";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import moment from "moment";
import Sidebar from "../components/Sidebar";

const RentBill = () => {
  const { state } = useLocation();
  console.log("STATE", state);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      date: moment().toDate(), // Set today's date as the default value
      others: 0,
      currentAmount: 0,
    },
  });

  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);

  const [details, setDetails] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  let hostelName = localStorage.getItem("hostelName");

  const getViewRegister = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/getAllNewRegister/${hostelName}`
    );
  };
  const getBillNo = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newbills/${hostelName}/lastBillNo`
    );
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/newbills/${hostelName}`,
        postData
      ),
    {
      onSuccess: () => {
        console.log("data added successfully...");
        setAddSubTicketPopUp(1);
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const { data } = useQuery("getViewRegister", getViewRegister);

  const registerDetails = data && data?.data;
  const { data: lastbill } = useQuery("getBillNo", getBillNo);
  let billgenerate = lastbill && lastbill?.data;

  const {
    currentAmount,
    rentForMonth,
    others,
    currentlyPaidAmount,
    powerUsedFrom,
    powerUsedTo,
  } = watch();

  const totalAmount = `${
    Number(currentAmount) + Number(rentForMonth) + Number(others)
  }`;
  const remainingbalance = `${
    Number(totalAmount) - Number(currentlyPaidAmount)
  }`;

  useEffect(() => {
    setValue("billNo", Number(billgenerate));
    setValue("total", totalAmount);
    setValue("remainingAmount", remainingbalance);
  }, [totalAmount, remainingbalance]);

  const onSubmit = (data) => {
    let formData = {
      name: data?.name,
      date: moment(data?.date).format("YYYY-MM-DD"),
      // moment(data.date).format('DD/MM/YYYY');
      mobileNumber: data?.mobileNumber,
      emailId: data?.email,
      roomNo: data?.roomNo,
      currentAmount: data?.currentAmount,
      others: data?.others,
      rentForMonth: data?.rentForMonth,
      role: data?.role,
      total: data?.total,
      billNo: data?.billNo,
      powerUsedTo: 0,
      powerUsedFrom: 0,
      currentlypaidAmount: 0,
      remainingAmount: 0,
      paymentType: data?.paymentType,
      selecthostel: "",
    };
    localStorage.setItem("role", data?.role);
    setDetails(formData);
    // console.log("formData", formData);
    if (formData) {
      mutate(formData);
    }
    // navigate('/billingInvoice',{state:formData})
  };
  const users = registerDetails?.filter(
    (item) => Number(item?.mobileNo) === Number(searchTerm)
  );
  const usersdetails = users?.[0] || {};
  // console.log("userdetails", usersdetails);
  useEffect(() => {
    if (searchTerm && searchTerm) {
      setValue("name", usersdetails?.name || "");
      setValue("roomNo", usersdetails?.roomNo || "");
      setValue("mobileNumber", usersdetails?.mobileNo || "");
      setValue("email", usersdetails?.emailId || "");
      setValue("rentForMonth", usersdetails?.rentForMonth || "");
    }
  }, [searchTerm, usersdetails, setValue]);
  useEffect(() => {
    setValue("name", state?.name || "");
    setValue("roomNo", state?.roomNo || "");
    setValue("mobileNumber", state?.mobileNo || "");
    setValue("email", state?.emailId || "");
    setValue("rentForMonth", state?.rentForMonth);
  }, []);
  return (
    <>
      <section className="rentBillMainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0 m-0">
            
              <div className="row pt-2 pb-3 px-2 m-0 new-register-heading-block">
                <div className="col-12 col-sm-4 col-md-4">
                  <div className="new-register-title-block">
                    <img
                      src="/assets/images/arrow-blue.png"
                      alt="no-image"
                      className="arrow-img-icon"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <h5 className="register-title pt-2">NEW BILL</h5>
                  </div>
                </div>
                <div className="col-12 col-sm-8 d-flex justify-content-between justify-content-md-end align-items-center gap-2">
                  <input
                    type="search"
                    className="view-bill-search"
                    placeholder="Mobile Number"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Controller
                    name="role"
                    control={control}
                    defaultValue="JSK WOMENS PG 1"
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`selectDropDown ${
                          errors.role ? "error" : ""
                        }`} // Conditional class for error
                      >
                        <option value="">Branch Name</option>
                        <option value="JSK WOMENS PG 1">JSK WOMENS PG 1</option>
                        <option value="JSK WOMENS PG 2">JSK WOMENS PG 2</option>
                        <option value="JSK WOMENS PG 3">JSK WOMENS PG 3</option>
                      </select>
                    )}
                  />
                  <button
                    className="view-register-btn"
                    onClick={() => {
                      navigate("/viewBill");
                    }}
                  >
                    VIEW BILL
                  </button>
                </div>
              </div>

              <div className="register-form-main-block">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="rentalFormBlock my-3"
                >
                  <div className=" py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Date <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="date"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <DatePicker
                            className="bookingDateEditInput"
                            onChange={onChange} // Call onChange to update the form state
                            onBlur={onBlur} // Call onBlur to trigger validation
                            selected={value} // Set the selected date
                            placeholderText="DD/MM/YY"
                            minDate={new Date()} // Prevent selecting past dates
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Bill No</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="billNo"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Bill Number"
                            type="number"
                            bookingEditInput="bookingEditInput"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Name
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="name"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Customer Name"
                            type="text"
                            bookingEditInput="bookingEditInput"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="mobileNumber"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Mobile Number"
                            type="number"
                            bookingEditInput="bookingEditInput"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Email ID</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="email"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Email Id"
                            type="emailid"
                            bookingEditInput="bookingEditInput"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Room No</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="roomNo"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Room No"
                            bookingEditInput="bookingEditInput"
                            type="text"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">EB Amount</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="currentAmount"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="currentAmount"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>

                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Rent For Month <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="rentForMonth"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Rent For Month"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  {/* OLD BALANCE START */}
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Others</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="others"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Others"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>

                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Total Amount
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="total"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Total"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Payment Method
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        name="paymentType"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }} // Example: Add validation rules
                        render={({ field }) => (
                          <select {...field} className="bookingEditInput">
                            <option value="">PAYMENT METHOD</option>
                            <option value="CASH PAY">CASH PAY</option>
                            <option value="UPI/BANK TRANSACTION">
                              UPI/BANK TRANSACTION
                            </option>
                          </select>
                        )}
                      />
                    </div>
                  </div>
                  <div className="py-4 px-4  billingSubmitButton">
                    <button type="submit" className="formSubmit">
                      {isLoading ? (
                        <i class="fa fa-spinner fa-spin"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => {
              setAddSubTicketPopUp(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContent py-2">Thank you!</span>
          <span className="popUpContentPara ">Bill added successfully..</span>
          <button
            onClick={() => {
              navigate("/viewBill");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default RentBill;
